import { Link } from "react-router-dom";
import { businesses } from "../pages/Landing";

export default function Bar4Services() {
  return (
    <div>
      {/* Services */}
      <section className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
        {/* Each service as a card */}
        {businesses.map((service, i) => (
          <div key={i} className="relative overflow-hidden bg-white rounded-md">
            <div className="flex items-center justify-center w-full h-64 p-5 bg-gray-200">
              <div>
                <img
                  src={service.image}
                  alt="Service Logo Placeholder"
                  className="w-16 h-16 mx-auto"
                />
                <h2 className="mt-2 text-2xl font-bold">{service.name}</h2>
              </div>
            </div>
            <div className="absolute inset-0 flex items-center justify-center p-5 transition-opacity opacity-0 hover:opacity-100 bg-white bg-opacity-90">
              <div className="space-y-4 text-center">
                <p className="text-lg">{service.description}</p>
                <button className="px-4 py-2 text-white bg-black rounded">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import SabLogo from "../logos/SAB-logo.png";
import SBRLogo from "../logos/SBR-logo.jpg";
import Hallwayz from "../logos/The-Hallwayz.png";
import EntBoot from "../logos/EntBootcampLogo.png";
import EntWhite from "../logos/ent-white.png";
import EntBlack from "../logos/ent-black.png";
import EntOrange from "../logos/ent-orange.png";
import Bar4Services from "../components/Bar4Services";
import GridServices from "../components/GridServices";

export const businesses = [
  {
    name: "The Hallwayz",
    description:
      "Embrace the flexibility you crave as an entrepreneur. Rent space at The Hallwayz for meetings, full-time work, podcast recordings, and more. Step into a collaborative environment that fuels creativity and productivity.",
    link: "/hallwayz",
    image: Hallwayz,
    alt: "The Hallwayz logo",
  },
  {
    name: "Entrepreneur Bootcamp",
    description:
      "Make your startup dream a reality. Enroll in our Entrepreneur Bootcamp for bespoke mentorship and guidance on crafting a solid business plan. Empower your entrepreneurial journey right from the start.",
    link: "/bootcamp",
    image: EntBoot,
    alt: "Entrepreneur Bootcamp logo",
  },
  {
    name: "Smart Business Results",
    description:
      "Gain an edge with expert guidance. Connect with industry professionals across various domains, from IT to Marketing to Operations, and everything in between. Leverage our consulting services to turbocharge your business success.",
    link: "/consulting",
    image: SBRLogo,
    alt: "Smart Business Results logo",
  },
  {
    name: "Start a Business in PA",
    description:
      "Navigate the complex legal landscape with ease. Our guided checklist will help you through the initial paperwork and logistics of forming a business in PA. Turn bureaucratic hurdles into stepping stones for your startup.",
    link: "/start",
    image: SabLogo,
    alt: "Start a Business in PA logo",
  },
];

const Landing = () => {
  return (
    <div className="flex flex-col h-full text-black bg-slate-200">
      {/* Navigation */}
      <nav className="flex justify-between p-5 bg-orange-500">
        <div className="flex space-x-10 text-white font-bold">
          <Link to="/">
            <img src={EntWhite} className="h-6" />
          </Link>
          <Link to="/hallwayz">The Hallwayz</Link>
          <Link to="/bootcamp">Entrepreneur Bootcamp</Link>
          <Link to="/consulting">Smart Business Results</Link>
          <Link to="/start">Start a Business in PA</Link>
        </div>
        <div className="flex space-x-10">
          <Link to="/about">About Us</Link>
          <Link to="/careers">Careers</Link>
        </div>
      </nav>
      {/* Hero */}
      <header className="flex items-center justify-center flex-1 text-center bg-orange-500 pb-12 pt-4">
        <div className="space-y-5 w-3/6 text-gray-800 mt-4">
          <img
            src={EntWhite}
            alt="Entrepreneur Thrive Logo"
            className="h-24 mx-auto"
          />
          <div>
            {/* <h2 className="text-2xl font-bold my-0">Helping the</h2> */}
            <h1 className="text-4xl text-white font-bold my-0">
              Entrepreneur Thrive
            </h1>
          </div>
          <p className="text-2xl">
            Empowering Businesses, Enriching Entrepreneurs
          </p>
          <p className="text-xl mx-8">
            From ideation to execution, and beyond - we offer a range of
            services designed to empower your business
          </p>
          <button className="mt-8 text-xl px-5 py-3 text-orange-500 font-bold bg-white shadow-sm rounded">
            Schedule a meeting
          </button>
        </div>
      </header>
      {/* Services */}
      <div className="mx-20 mt-8 text-2xl">
        Check out the ways we can help you thrive
      </div>
      {/* <GridServices /> */}
      <Bar4Services />

      {/* Testimonials */}
      {/* <section className="p-5 bg-white">
        <h2 className="text-3xl font-bold">What Our Clients Say</h2>
        <div className="grid grid-cols-1 gap-10 md:grid-cols-3">
          {[...Array(3)].map((_, i) => (
            <blockquote key={i}>
              <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit."</p>
              <footer>- Client {i + 1}</footer>
            </blockquote>
          ))}
        </div>
      </section> */}
      {/* Footer */}
      <footer className="p-5 bg-gray-700 text-white flex flex-row gap-x-8">
        <address>840 First Ave; Suite 400, King of Prussia, PA 19406</address>
        <a href="mailto:bill@entrepreneur-thrive.com">
          bill@entrepreneur-thrive.com
        </a>
        <a href="tel:6109451750">(610) 945-1750</a>
      </footer>
    </div>
  );
};

export default Landing;
